
export const feeType = [{
  text: '03',
  value: '提现'
},
{
  text: '04',
  value: '充值'
},
{
  text: 'SMS',
  value: '短信充值'
},
{
  text: 'INSURANCE',
  value: '保险充值'
},
{
  text: 'AUTOCFRT02',
  value: '自动到账'
},
{
  text: 'DF',
  value: '垫付'
},
{
  text: 'GOODSPRICE',
  value: '代收款'
},
{
  text: 'HK',
  value: '回款'
},
{
  text: 'INFOFEE',
  value: '信息费'
},
{
  text: 'INFOFEE_AUTO_CFRA01',
  value: '信息费'
},
{
  text: 'LogKHuiFu',
  value: '收款'
},
{
  text: 'LogKTiFu',
  value: '收款'
},
{
  text: 'LogKXianFu',
  value: '收款'
},
{
  text: 'LogMPTiFu',
  value: '收款'
},
{
  text: 'OLScanPay',
  value: '充值'
},
{
  text: 'REFUND',
  value: '退款'
},
{
  text: 'REFUNDFEE',
  value: '返款费'
},
{
  text: 'SERVICEFEE',
  value: '手续费'
},
{
  text: 'StaticQRCode',
  value: '静态码收款'
},
{
  text: 'TRANSFERFEE1',
  value: '车费'
},
{
  text: 'TRANSFERFEE2',
  value: '车费'
},
{
  text: 'TRANSFERFEE3',
  value: '车费'
},
{
  text: 'TRANSFERINFEE',
  value: '返款费'
},
{
  text: 'TRANSIT2FEE',
  value: '中转支出'
},
{
  text: 'TRANSPORT2FEE',
  value: '车费'
},
{
  text: 'TRANSPORT3FEE',
  value: '车费'
},
{
  text: 'ZZ',
  value: '转账'
}
]
export const fee = [{
  text: '全部类型',
  value: ''
},
{
  text: '代收款',
  value: ['GOODSPRICE']
},
{
  text: '提现',
  value: ['03', 'AUTOCFRT02']
},
{
  text: '信息费',
  value: ['INFOFEE', 'INFOFEE_AUTO_CFRA01']
},
{
  text: '返款费',
  value: ['REFUNDFEE', 'TRANSFERINFEE']
},
{
  text: '垫付货款',
  value: ['DF']
}, {
  text: '车费',
  value: ['TRANSFERFEE1', 'TRANSFERFEE2', 'TRANSFERFEE3', 'TRANSFERFEEE', 'TRANSPORT2FEE',
    'TRANSPORT3FEE'
  ]
}, {
  text: '收款',
  value: ['LogKHuiFu', 'LogKTiFu', 'LogKXianFu', 'LogMPTiFu']
},
{
  text: '充值',
  value: ['04', 'OLScanPay', 'SMS', 'INSURANCE']
},
{
  text: '静态码收款',
  value: ['StaticQRCode']
},
{
  text: '手续费',
  value: ['SERVICEFEE']
}

]
export const feeOut = [{
  text: '全部类型',
  value: ''
},
{
  text: '提现',
  value: '03'
},

{ text: '退款', value: 'REFUND' },
{ text: '其他', value: 'OTHER' }
]

export const feeIn = [{
  text: '全部类型',
  value: ''
},
{
  text: '保险充值',
  value: 'INSURANCE'
},

{ text: '系统续费', value: 'SYSTEM' },
{ text: '短信充值', value: 'SMS' },
{ text: '手续费', value: 'SERVICEFEE' },
{ text: '其他', value: 'OTHERPAY' }

]

export const feeTypeIn = [{
  text: '全部类型',
  value: ''
},
{
  text: '保险充值',
  value: 'INSURANCE'
},
{ text: '系统续费', value: 'SYSTEM' },
{ text: '短信充值', value: 'SMS' },
{ text: '手续费', value: 'SERVICEFEE' },
{ text: '其他收入', value: 'OTHERPAY' },
{
  text: '提现',
  value: '03'
},
{ text: '退款', value: 'REFUND' },
{ text: '其他支出', value: 'OTHER' }
]
